import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, distinctUntilKeyChanged, finalize, mergeMap, scan, startWith, switchMap, tap } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/security/loader.service';

import { Company } from '../../../app/shared/model/company.model';
import { CompanyService } from '../../../app/shared/services';
import { CompanySettingsService } from '../../../app/shared/services/company-settings.service';
import { ToastService } from './../../shared/services/toast.service';

@Component({
  selector: 'app-profile-scope',
  templateUrl: './profile-scope.component.html',
  styleUrls: ['./profile-scope.component.scss'],
})
export class ProfileScopeComponent implements OnInit {
  // * Variables

  public companyId: string;
  public company: Company;
  public isLoading: boolean;

  // * Objects
  public fgSettings: FormGroup;

  // * Observables
  public getDataObj$ = new Observable<any>();
  public fcSuperDigital$ = new Observable<string>();
  public fcWorkspaceData$ = new Observable<string>();
  public fcAtsPopUpEnabled$ = new Observable<string>();
  public fcAtsBannerEnabled$ = new Observable<string>();

  constructor(
    private _formBuilder: FormBuilder,
    private _toastService: ToastService,
    private _loaderService: LoaderService,
    private _companyService: CompanyService,
    private _activatedRouter: ActivatedRoute,
    private _companySettingsService: CompanySettingsService,
  ) {}

  ngOnInit() {
    this._createFormGroup();

    this.getDataObj$ = this._getUpdatedDataObj$;
    this.fcSuperDigital$ = this._getUpdatedFcSuperDigital$;
    this.fcWorkspaceData$ = this._getUpdatedFcWorkspaceData$;
    this.fcAtsPopUpEnabled$ = this._getUpdatedFcAtsPopUp$;
    this.fcAtsBannerEnabled$ = this._getUpdatedFcAtsBanner$;
  }

  private _createFormGroup() {
    this.fgSettings = this._formBuilder.group({
      isAtsPopUpEnabled: new FormControl(false, Validators.required),
      isAtsBannerEnabled: new FormControl(false, Validators.required),
      isSuperDigitalEnabled: new FormControl(false, Validators.required),
      isWorkspaceDataEnabled: new FormControl(false, Validators.required),
    });
  }

  private get _getUpdatedDataObj$(): Observable<any> {
    return this._activatedRouter?.params?.pipe(
      distinctUntilKeyChanged('id'),
      switchMap((params: any) =>
        this._companyService?.getOne(params?.id)?.pipe(
          tap((response) => {
            this.fgSettings.get('isAtsPopUpEnabled').setValue(!response?.settings?.enablePopUp, { emitEvents: false });
            this.fgSettings.get('isAtsBannerEnabled').setValue(!response?.settings?.enableBanner, { emitEvents: false });
            this.fgSettings.get('isSuperDigitalEnabled')?.setValue(!response?.settings?.enableOpeningBankAccount, { emitEvent: false });
            this.fgSettings.get('isWorkspaceDataEnabled')?.setValue(!response?.settings?.useWorkspaceData, { emitEvent: false });
          }),
          mergeMap(() => of({ form: this.fgSettings, loading: false })),
        ),
      ),
      startWith({ form: this.fgSettings, loading: true }),
      scan((state, change) => ({ ...state, ...change })),
    );
  }

  private get _getUpdatedFcAtsPopUp$() {
    return combineLatest([
      this._activatedRouter?.params?.pipe(startWith()),
      this.fgSettings?.get('isAtsPopUpEnabled')?.valueChanges,
    ])?.pipe(
      tap(() => this._loaderService.show()),
      switchMap(([params, value]) =>
        this._companySettingsService?.patchSwitchEnablePopUpVisualTrigger({ companyId: params?.id, enablePopUp: !value })?.pipe(
          tap(() => this._toastService?.success('Configuração alterada com sucesso')),
          catchError(() => {
            this._toastService?.error('Erro ao alterar configuração');
            return of('isAtsPopUpEnabled');
          }),
          mergeMap(() => of('isAtsPopUpEnabled')),
          finalize(() => this._loaderService.hide()),
        ),
      ),
      startWith('isAtsPopUpEnabled'),
    );
  }

  private get _getUpdatedFcAtsBanner$() {
    return combineLatest([
      this._activatedRouter?.params?.pipe(startWith()),
      this.fgSettings?.get('isAtsBannerEnabled')?.valueChanges,
    ])?.pipe(
      tap(() => this._loaderService.show()),
      switchMap(([params, value]) =>
        this._companySettingsService?.patchSwitchEnableBannerVisualTrigger({ companyId: params?.id, enableBanner: !value })?.pipe(
          tap(() => this._toastService?.success('Configuração alterada com sucesso')),
          catchError(() => {
            this._toastService?.error('Erro ao alterar configuração');
            return of('isAtsBannerEnabled');
          }),
          mergeMap(() => of('isAtsBannerEnabled')),
          finalize(() => this._loaderService.hide()),
        ),
      ),
      startWith('isAtsBannerEnabled'),
    );
  }

  private get _getUpdatedFcSuperDigital$() {
    return combineLatest([
      this._activatedRouter?.params?.pipe(startWith()),
      this.fgSettings?.get('isSuperDigitalEnabled')?.valueChanges,
    ])?.pipe(
      tap(() => this._loaderService.show()),
      switchMap(([params, value]) =>
        this._companySettingsService?.patchEnableOpeningBankAccount({ companyId: params?.id, enableOpeningBankAccount: !value })?.pipe(
          tap(() => this._toastService?.success('Configuração alterada com sucesso')),
          catchError(() => {
            this._toastService?.error('Erro ao alterar configuração');
            return of('isSuperDigitalEnabled');
          }),
          mergeMap(() => of('isSuperDigitalEnabled')),
          finalize(() => this._loaderService.hide()),
        ),
      ),
      startWith('isSuperDigitalEnabled'),
    );
  }

  private get _getUpdatedFcWorkspaceData$() {
    return combineLatest([
      this._activatedRouter?.params?.pipe(startWith()),
      this.fgSettings?.get('isWorkspaceDataEnabled')?.valueChanges,
    ])?.pipe(
      tap(() => this._loaderService.show()),
      switchMap(([params, value]) =>
        this._companySettingsService?.setUseWorkspaceData({ companyId: params?.id, useWorkspaceData: !value })?.pipe(
          tap(() => this._toastService?.success('Configuração alterada com sucesso')),
          catchError(() => {
            this._toastService?.error('Erro ao alterar configuração');
            return of('isWorkspaceDataEnabled');
          }),
          mergeMap(() => of('isWorkspaceDataEnabled')),
          finalize(() => this._loaderService.hide()),
        ),
      ),
      startWith('isWorkspaceDataEnabled'),
    );
  }
}
