import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DigitalSignatureConfigWorkspace } from '../model/digital-signature-config-workspace';

@Injectable({
    providedIn: 'root'
})
export class DigitalSignatureConfigService {
    private baseUrl = `${environment.apiEndpoint}/digitalSignature/config`;
    constructor(private httpClient: HttpClient) { }

    getConfigForCompany(companyId: string): Promise<DigitalSignatureConfigWorkspace[]> {
        let url = `${this.baseUrl}/company/${companyId}`;
        return this.httpClient.get<DigitalSignatureConfigWorkspace[]>(url).toPromise();
    }

    toggleForAllWorkspaces(companyId: string, enable: boolean) {
        let url = `${this.baseUrl}/company/${companyId}/enable/${enable}`;
        return this.httpClient.patch(url, {}).toPromise();
    }

    toggleForOneWorkspace(companyId: string, workspaceId: string, enable: boolean) {
        let url = `${this.baseUrl}/company/${companyId}/workspace/${workspaceId}/enable/${enable}`;
        return this.httpClient.patch(url, {}).toPromise();
    }
}
