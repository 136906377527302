import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { CompanySettings } from '../model/company-settings.model';
import { UpdateAtsBanner, UpdateAtsPopUp } from '../model/update-ats-triggers.model';
import { UseWorkspaceDataCmd } from '../model/use-workspace-data-cmd.model';
import { UpdateSuperDigitalConfig } from './../model/update-super-digital-config.model';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingsService {
  constructor(private http: HttpClient) {}

  // ! Settings for company features
  public postCompanySettings(companySettings: CompanySettings) {
    let url = `${environment.apiEndpoint}/Company/CompanyConfig`;
    return this.http.put(url, companySettings);
  }

  public setUseWorkspaceData(cmd: UseWorkspaceDataCmd): Observable<Object> {
    let url = `${environment.apiEndpoint}/Company/Config/UseWorkspaceData`;
    return this.http.patch(url, cmd);
  }

  public patchEnableOpeningBankAccount(cmd: UpdateSuperDigitalConfig): Observable<any> {
    const url = `${environment.apiEndpoint}/Company/Config/EnableOpeningBankAccount`;
    return this.http.patch(url, cmd);
  }

  public patchSwitchEnablePopUpVisualTrigger(cmd: UpdateAtsPopUp): Observable<any> {
    const url = `${environment.apiEndpoint}/Company/Config/SwitchEnablePopUpVisualTrigger`;
    return this.http.patch(url, cmd);
  }

  public patchSwitchEnableBannerVisualTrigger(cmd: UpdateAtsBanner): Observable<any> {
    const url = `${environment.apiEndpoint}/Company/Config/SwitchEnableBannerVisualTrigger`;
    return this.http.patch(url, cmd);
  }
}
