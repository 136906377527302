<ng-container *ngIf="getDataObj$ | async as obj">
  <mat-card class="container-load mat-elevation-z4" *ngIf="obj?.loading">
    <section class="load-content">
      <app-loading></app-loading>
    </section>
  </mat-card>

  <mat-card class="card-container mat-elevation-z4" *ngIf="!obj?.loading">
    <mat-card-title class="card-title">Configurações</mat-card-title>
    <mat-card-content class="card-content">
      <form class="card-form" [formGroup]="obj?.form">
        <mat-slide-toggle [formControlName]="fcSuperDigital$ | async"> Ativar Super Digital para a empresa. </mat-slide-toggle>
        <mat-slide-toggle [formControlName]="fcWorkspaceData$ | async"> Utilizar dados do workspace nas cartas de e-mail </mat-slide-toggle>
        <mat-slide-toggle [formControlName]="fcAtsPopUpEnabled$ | async"> Mostrar modal (pop up) do ATS para a empresa. </mat-slide-toggle>
        <mat-slide-toggle [formControlName]="fcAtsBannerEnabled$ | async"> Mostrar banner do ATS na person-list para a empresa. </mat-slide-toggle>
      </form>
    </mat-card-content>
  </mat-card>
</ng-container>
